<template>
    <div class="">
        <div class="flex justify-space-between">
            <div class="box self-flex-end"><h2 class="mv-0">SUBSCRIPTION</h2></div>
            <div class="box"><el-button @click="$onCommandParams('home')">BACK</el-button></div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <div class="flex wrap grid-12">
          <app-panel
            :eventId="currentBusketItem.eventId"
            :item="l"
            @switchEvent="switchSubscription"
            v-for="(l, index) in filtered" :key="index + '-subscription'"></app-panel>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <app-select-customer
          @selectCustomer="selectCustomer"
          :customerId="currentBusketItem.customerId"
          :basketWaiwers="basketWaiwers"></app-select-customer>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import AppPanel from './components/LessonPanel'
import AppSelectCustomer from './components/LessonSelectCustomer'

export default {
  name: 'SaleSubscriptions',

  components: {
    AppPanel,
    AppSelectCustomer
  },

  props: {
    members: {
      type: Array,
      default: function () {
        return []
      }
    },
    basket: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data () {
    return {}
  },
  computed: {
    ...mapState({
      subscriptions: state => state.subscription.subscriptions
    }),
    ...mapGetters([
      'getGroupById',
      'basketWaiwers',
      'getMemberById'
    ]),
    currentBusketItem () {
      return this.basket.find(b => b.basketId === this.$route.params.id) || {}
    },
    filtered () {
      if (this.currentBusketItem.customerId !== '') {
        let member = this.getMemberById[this.currentBusketItem.customerId]
        if (member && member.hasOwnProperty('group')) {
          if (this.getGroupById[member.group].isVip) {
            return this.subscriptions
          }
        }
      }
      return this.subscriptions.filter(s => !s.isForVIPOnly)
    }
  },
  methods: {
    ...mapActions([
      'editBasketItem',
      'remoteBasketItem'
    ]),
    switchSubscription ({ id, name, cost }) {
      let desc = this.currentBusketItem.desc.map((x) => x)
      desc.splice(0, 1, name)

      this.editBasketItem({
        basketId: this.$route.params.id,
        content: {
          desc,
          rate: cost,
          eventId: id
        }
      })
    },
    selectCustomer (value) {
      let customerName = this.basketWaiwers.find(b => b.value === value).label
      this.editBasketItem({
        basketId: this.$route.params.id,
        content: {
          customerId: value,
          customerName
        }
      })
    }
  },
  activated: function () {
    if (this.currentBusketItem.customerId === '' && this.basketWaiwers.length > 0) {
      this.editBasketItem({
        basketId: this.$route.params.id,
        content: {
          times: 1,
          customerId: this.basketWaiwers[0].value,
          customerName: this.basketWaiwers[0].label
        }
      })
    }
  },
  deactivated: function () {
    if (this.currentBusketItem.times === 0) {
      this.remoteBasketItem({ basketId: this.$route.params.id })
    }
  }
}
</script>
